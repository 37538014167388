<template>
  <div class="container px-2 md:px-0">
    <h1>Hive <i class="mdi mdi-menu-right text-lg"></i> Checkout</h1>
    <div class="w-full flex mt-8">
      <div
        class="w-1/3 border text-center py-4 uppercase text-xs tracking-widest"
        :class="active == s ? 'border-b-0' : null"
        v-for="(step, s) in steps"
        :key="s"
      >
        {{ s + 1 }} - {{ step.label }}
      </div>
    </div>
    <div class="w-full border border-t-0 px-8 py-4 mb-12">
      <component
        :is="activeStep"
        @sendOrder="sendOrder"
        @nextStep="createOrder"
        @addRemoney="addRemoneyButton"
        :remoneyCart="remoneyCart"
      ></component>
    </div>
    <!-- <div class="mt-12">
      <div
        class="w-full grid grid-cols-12 gap-4 mb-4 py-4 "
        v-for="(item, i) in items"
        :key="i"
      >
        <div class="col-span-3">
          <img
            :src="item.product_photo"
            class="w-full h-40 rounded-md object-cover"
          />
        </div>
        <div class="col-span-8 flex flex-col">
          <h1 class="text-xl capitalize">{{ item.product_name }}</h1>
          <p class="flex-grow" v-html="item.product_description"></p>
          <span>{{ item.quantity }} x {{ item.single_unit_price }}</span>
        </div>
        <div class="col-span-1">
          <button class="w-12 h-12 border border-red-500  rounded-md">
            <i class="mdi mdi-close text-3xl text-primary-500 fill-current"></i>
          </button>
        </div>
      </div>
      <hr />
      <h2 class="py-8 text-3xl font-bold text-right">Total: {{ cartTotal }}</h2>
      <q-button :cta="true" @click="sendOrder">Invia Ordine</q-button>
      <div
        id="remoney___btn"
        data-apiKey="uWZ6kOK0Z9aOtPrpk3y0k5bpPcrkWOaP2kzjpCYi"
        :data-items="remoneyCart"
        data-recipient="20201020-573a58fc3426429eb6baf25d0d8a5009"
        :data-amount="cartTotal"
        data-callbackUrl="https://mysite/transaction-status"
        ref="paywit"
      ></div>
      <div class="h-32"></div>
    </div> -->
  </div>
</template>

<script>
//let vm;

function paymentDone(s) {
  console.log("%cRemoney is Done!🤌🏼🤌🏼🤌🏼 ", "font-size:1.3rem;color:lime", s);
  window.vm.cartComponent.sendOrder();
}
window.paymentDone = paymentDone;
import { mapGetters, mapMutations, mapState } from "vuex";
import { API_URL } from "@/app-axios";
export default {
  data() {
    return {
      remoneyCart: {
        name: "",
        surname: "",
        email: "",
        callback_url: "{{url}}/smartpay/transaction-status",
        recipient_code: "",
        amount: 0,
        multi_order: true,
        json_cart: {}
      },
      active: 0,
      steps: [
        { label: "Conferma Ordine" },
        { label: "Paga" },
        { label: "Esito" }
      ]
    };
  },
  components: {
    Step1: () => import("./_components/Step1.vue"),
    Step2: () => import("./_components/Step2.vue"),
    Step3: () => import("./_components/Step3.vue")
  },
  created() {
    console.log("CREATED", this.currentUserData);
    this.SHOW_CART(false);
    // if (this.currentUserData) {
    //   this.remoneyCart.name = this.currentUserData.attributes.name;
    //   this.remoneyCart.surname = this.currentUserData.attributes.family_name;
    //   this.remoneyCart.email = this.currentUserData.attributes.email;
    //   this.remoneyCart.recipient_code =
    //     "20201020-573a58fc3426429eb6baf25d0d8a5009"; //res.data.remoney_payment_code;
    //   this.remoneyCart.amount = this.cartTotal;
    //   this.remoneyCart.multi_order = true;
    //   let it = this.items.map(i => {
    //     return {
    //       item_id: i.product_id,
    //       item_name: i.product_name,
    //       quantity: i.quantity,
    //       total_amount:
    //         parseFloat(i.single_unit_price) * parseFloat(i.quantity),
    //       single_amount: i.single_unit_price,
    //       recipient_code: i.remoney_payment_code,
    //       charge_at: new Date()
    //     };
    //   });

    //   this.remoneyCart.json_cart.items = it;
    //   //this.remoneyCart.json_cart = {items:[...this.items]};
    // }
  },
  mounted() {
    window.vm.cartComponent = this;
  },
  computed: {
    ...mapState("cart", ["items"]),
    ...mapGetters("cart", ["cartTotal"]),
    ...mapGetters("auth", ["currentUserData"]),
    activeStep() {
      return "Step" + (this.active + 1);
    }
  },
  destroyed() {
    //when cart is closed remove remoney_sdk
    if (document.getElementById("remoney___sdk")) {
      document.getElementById("remoney___sdk").remove();
    }
  },
  methods: {
    ...mapMutations("cart", ["SHOW_CART", "EMPTY_CART"]),
    addRemoneyButton() {
      if (this.items.length > 0) {
        (function(d, s, id) {
          let js,
            rjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.async = false;
          js.defer = true;
          js.src = process.env.REMONEY_BTN_URL + "/pay.js?callback=paymentDone";
          rjs.parentNode.insertBefore(js, rjs);
        })(document, "script", "remoney___sdk");
      }
    },
    createOrder() {
      console.log("Create order");
      this.remoneyCart.name = this.currentUserData.attributes.name;
      this.remoneyCart.surname = this.currentUserData.attributes.family_name;
      this.remoneyCart.email = this.currentUserData.attributes.email;
      this.remoneyCart.recipient_code =
        "20201020-573a58fc3426429eb6baf25d0d8a5009"; //res.data.remoney_payment_code;
      this.remoneyCart.amount = this.cartTotal;
      this.remoneyCart.multi_order = true;
      let it = this.items.map(i => {
        return {
          item_id: i.product_id,
          item_name: i.product_name,
          quantity: i.quantity,
          total_amount:
            parseFloat(i.single_unit_price) * parseFloat(i.quantity),
          single_amount: i.single_unit_price,
          recipient_code: i.remoney_payment_code,
          charge_at: new Date()
        };
      });
      console.log("Items", it);
      this.remoneyCart.json_cart.items = it; //JSON.stringify(it);
      this.active++;
    },
    sendOrder() {
      console.log("Sending order ", this.remoneyCart);

      let data = {
        customer_id: this.currentUserData.attributes.sub,
        customer_name: this.currentUserData.attributes.name,
        customer_email: this.currentUserData.attributes.email,
        products: [...this.items]
      };
      console.log("Invio Ordine", data);

      API_URL({
        url: "/orders",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "aws-access-token": this.$store.getters["auth/token"]
        },
        data: data
      })
        .then(res => {
          console.log("Ordine Inviato ", res);
          // this.remoneyCart.name = this.currentUserData.attributes.name;
          // this.remoneyCart.surname = this.currentUserData.attributes.family_name;
          // this.remoneyCart.email = this.currentUserData.attributes.email;
          // this.remoneyCart.recipient_code = res.data.remoney_payment_code;
          // this.remoneyCart.amount = this.cartTotal;
          // this.remoneyCart.multi_order = true;
          // let it = res.data.products_list.map(i => {
          //   return {
          //     item_id: i.product_id,
          //     item_name: i.product_name,
          //     quantity: i.quantity,
          //     total_amount: i.product_total,
          //     single_amount: i.single_unit_price,
          //     recipient_code: i.remoney_payment_code,
          //     charge_at: "2020-11-25T15:05:44"
          //   };
          // });
          // this.remoneyCart.json_cart.items = it;
          this.EMPTY_CART();
        })
        .catch(err => {
          console.log("Errore di invio ordine ", err);
        });
      this.active++;
    }
  }
};
</script>

<style></style>
